import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
let mm = gsap.matchMedia();

ScrollTrigger.create({
   trigger: '.services',
   //scroller: scroller,
   start: "-3.472vw top",
   end: "bottom-=3.472vw top",
   //markers: true,
   onEnter: () => gsap.to('.menu-btn-link-icon', {
      fill: "#121221"
   }),
   onLeave: () => gsap.to('.menu-btn-link-icon', {
      fill: "#fff"
   }),
   onLeaveBack: () => gsap.to('.menu-btn-link-icon', {
      fill: "#fff"
   }),
   onEnterBack: () => gsap.to('.menu-btn-link-icon', {
      fill: "#121221"
   }),
});
ScrollTrigger.create({
   trigger: '.reviews',
   //scroller: scroller,
   start: "-3.472vw top",
   end: "bottom-=3.472vw top",
   markers: false,
   onEnter: () => gsap.to('.menu-btn-link-icon', {
      fill: "#121221"
   }),
   onLeave: () => gsap.to('.menu-btn-link-icon', {
      fill: "#121221"
   }),
   onLeaveBack: () => gsap.to('.menu-btn-link-icon', {
      fill: "#fff"
   }),
   onEnterBack: () => gsap.to('.menu-btn-link-icon', {
      fill: "#121221"
   }),
});
ScrollTrigger.create({
   trigger: '.cta',
   //scroller: scroller,
   start: "-3.472vw top",
   end: "bottom-=3.472vw top",
   markers: false,
   onEnter: () => gsap.to('.menu-btn-link-icon', {
      fill: "#fff"
   }),
   onLeave: () => gsap.to('.menu-btn-link-icon', {
      fill: "#fff"
   }),
   onLeaveBack: () => gsap.to('.menu-btn-link-icon', {
      fill: "#121221"
   }),
   onEnterBack: () => gsap.to('.menu-btn-link-icon', {
      fill: "#fff"
   }),
});
mm.add("(max-width: 480px)", () => {
   ScrollTrigger.create({
      trigger: '.pin-more-project',
      //scroller: scroller,
      start: "-3.472vw top",
      end: "bottom-=3.472vw top",
      markers: false,
      onEnter: () => gsap.to('.menu-btn-link-icon', {
         fill: "#fff"
      }),
      onLeave: () => gsap.to('.menu-btn-link-icon', {
         fill: "#121221"
      }),
      onLeaveBack: () => gsap.to('.menu-btn-link-icon', {
         fill: "#121221"
      }),
      onEnterBack: () => gsap.to('.menu-btn-link-icon', {
         fill: "#fff"
      }),
   });
});