import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Observer from "gsap/Observer";
import Draggable from "gsap/Draggable";
import {
   horizontalLoop
} from "./horizontal-loop"

gsap.registerPlugin(ScrollTrigger, Draggable, Observer);

let mm = gsap.matchMedia();
const vw = (coef) => window.innerWidth * (coef / 100);


mm.add("(max-width: 480px)", () => {
   gsap.set(".main-page-cases .item", {
      autoAlpha: 0,
      y: 60,
      opacity: 0,
   });

   ScrollTrigger.batch(".main-page-cases .item", {
      once: true,
      onEnter: elements => {
         gsap.fromTo(elements, {
            autoAlpha: 0,
            y: 60,
            opacity: 0,
         }, {
            autoAlpha: 1,
            y: 0,
            opacity: 1,
            duration: 1,
            ease: "power4.easeOut",
            stagger: 0.30
         });
      },
   });

   gsap.utils.toArray('.case-item-box-gallery').forEach((container, i) => {
      let tl = horizontalLoop(container.querySelectorAll(".case-item-box-gallery-left-item"), {
            paused: true,
            repeat: -1,
            center: true,
            speed: 0.8,
            draggable: true,
            paddingRight: vw(2.083),
         }),
         clamp = gsap.utils.clamp(-2, 2),
         isOver, reversedOnPause;

      let observer = Observer.create({
         target: document.scrollingElement,
         type: "scroll,wheel",
         wheelSpeed: 0.5,
         tolerance: 10,
         onChangeY: self => {
            //tl.timeScale(clamp(self.velocityY * 0.003));
            //console.log(clamp(self.velocityY * 0.003));
            gsap.to(tl, {
               timeScale: clamp(self.velocityY * 0.003),
               duration: 0.5,
               ease: "power1.out",
            });
         }
      });

      gsap.to(tl, {
         scrollTrigger: {
            trigger: ".pin-more-project",
            start: "top bottom",
            //end: "bottom top",
            //containerAnimation: mica,
            //markers: true,
            onEnter: elements => {
               tl.play();
               observer.enable();
            },
            onLeave: elements => {
               tl.pause();
               observer.disable();
            },
            onEnterBack: elements => {
               tl.play();
               observer.enable();
            },
            onLeaveBack: elements => {
               tl.pause();
               observer.disable();
            },
            //onUpdate: function (self) {
            //   self.direction === -1 ? tl.timeScale(-1) : tl.timeScale(1);
            //}
         }
      });
      gsap.to(tl, {
         scrollTrigger: {
            trigger: ".services",
            start: "+=100vh",
            //markers: true,
            onEnter: elements => {
               tl.pause();
               observer.disable();
            },
            onLeaveBack: elements => {
               tl.play();
               observer.enable();
            },
         }
      });
   });
   gsap.utils.toArray('.case-item-box-gallery').forEach((container, i) => {
      let tl = horizontalLoop(container.querySelectorAll(".case-item-box-gallery-right-item"), {
            paused: true,
            repeat: -1,
            center: true,
            speed: 0.6,
            draggable: true,
            paddingRight: vw(2.083),
         }),
         clamp = gsap.utils.clamp(-1, 1),
         isOver, reversedOnPause;

      let observer = ScrollTrigger.observe({
         target: document.scrollingElement,
         type: "scroll,wheel",
         wheelSpeed: 0.5,
         tolerance: 8,
         onChangeY: self => {
            //console.log(clamp(self.velocityY * 0.003));
            gsap.to(tl, {
               timeScale: clamp(self.velocityY * 0.003),
               duration: 0.4,
               ease: "power1.out",
            });
         }
      });
      observer.disable();
      gsap.to(tl, {
         scrollTrigger: {
            trigger: container,
            start: "top bottom",
            end: "+=125%",
            //scroller: scroller,
            //containerAnimation: mica,
            //markers: true,
            onEnter: elements => {
               tl.play();
               observer.enable();
            },
            onLeave: elements => {
               tl.pause();
               observer.disable();
            },
            onEnterBack: elements => {
               tl.play();
               observer.enable();
            },
            onLeaveBack: elements => {
               tl.pause();
               observer.disable();
            },
            //onUpdate: function (self) {
            //   self.direction === -1 ? tl.timeScale(-1) : tl.timeScale(1);
            //}
         }
      });
      gsap.to(tl, {
         scrollTrigger: {
            trigger: ".services",
            start: "+=100vh",
            //markers: true,
            onEnter: elements => {
               tl.pause();
               observer.disable();
            },
            onLeaveBack: elements => {
               tl.play();
               observer.enable();
            },
         }
      });
   });

});